import { ApiCall } from "common/types/api";
import { recordsApi } from "common/api/records";
import { behaveAs } from "common/entities";
import { hasProtectedColumns } from "common/record/utils";
import { getLocalizedName } from "common/index";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";

export const Open = (props: PropTypes) => {
  const { context, records = [], entity } = props;

  const openRecord = (apiCall: ApiCall) =>
    recordsApi(apiCall).open(entity.name, records[0].properties.id);

  const openRecordList = (apiCall: ApiCall) =>
    recordsApi(apiCall).bulkOpen(
      entity.name,
      records.map((record) => record.properties.id),
    );

  const onOpen = (apiCall: ApiCall, dismiss: DismissAction) => {
    const promise =
      records.length > 1 ? openRecordList(apiCall) : openRecord(apiCall);

    return promise.then(() => dismiss(true));
  };

  const getContent = () => {
    const woEntityName = getLocalizedName(entity);
    const swoEntityName = getLocalizedName(
      context.entities[entity.arguments.scheduledEventEntity],
    );
    return (
      <div className="x-open-wo-content">
        {records.length > 1 ? (
          <>
            <p>
              {_(
                "This action will open these {WORK_ORDER_ENTITY} records and update the last open date and the schedule for the Preventive Maintenance {SWO_ENTITY} records.",
              )
                .replace("{WORK_ORDER_ENTITY}", woEntityName)
                .replace("{SWO_ENTITY}", swoEntityName)}
              <b>
                {_(
                  "All the future {WORK_ORDER_ENTITY} records scheduled before these record will be deleted.",
                ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
              </b>
            </p>
            <p>
              {_(
                "If these records were rescheduled, this will generate future {WORK_ORDER_ENTITY} records based on the new schedule.",
              ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
            </p>
          </>
        ) : (
          <>
            <p>
              {_(
                "This action will open this {WORK_ORDER_ENTITY} record and update the last open date and the schedule for the Preventive Maintenance {SWO_ENTITY} record.",
              )
                .replace("{WORK_ORDER_ENTITY}", woEntityName)
                .replace("{SWO_ENTITY}", swoEntityName)}
              <b>
                {_(
                  "All the future {WORK_ORDER_ENTITY} records scheduled before this record will be deleted.",
                ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
              </b>
            </p>
            <p>
              {_(
                "If this record was rescheduled, this will generate future {WORK_ORDER_ENTITY} records based on the new schedule.",
              ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
            </p>
          </>
        )}
      </div>
    );
  };

  return (
    <Action
      {...props}
      requiresAuthentication={hasProtectedColumns(entity)}
      size={"large"}
      title={_("Open")}
      btnLabel={records.length > 1 ? _("Open records") : _("Open")}
      onOk={onOpen}
    >
      {behaveAs("WorkOrder", entity) ? getContent() : undefined}
    </Action>
  );
};
