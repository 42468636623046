import { Component } from "common/component";

interface PropTypes {
  fn: () => void;
  shouldPoll: boolean;
  intervalSeconds?: number;
  callOnStart?: boolean;
}

export class Polling extends Component<PropTypes> {
  static readonly displayName = "Polling";
  timeout: NodeJS.Timeout = undefined;

  componentDidMount() {
    this.configure();
  }

  componentDidUpdate() {
    this.configure();
  }

  componentWillUnmount() {
    this.stop();
  }

  configure = () => {
    const { shouldPoll } = this.props;
    const polling = !!this.timeout;

    if (polling && !shouldPoll) return this.stop();
    if (!polling && shouldPoll) return this.start();
  };

  start = () => {
    const { fn, intervalSeconds, callOnStart } = this.props;
    this.stop();
    this.timeout = setInterval(fn, 1000 * (intervalSeconds || 1));
    if (callOnStart) fn();
  };

  stop = () => {
    if (this.timeout) clearInterval(this.timeout);
    this.timeout = undefined;
  };

  render() {
    return <span />;
  }
}
