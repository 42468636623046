import { recordsApi } from "common/api/records";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { getLocalizedName } from "common/index";
import { AlertInfo } from "common/widgets/alert";
import { Action } from "common/record/actions/action";
import { PropTypes } from "common/record/actions/types";
import { mapPayload } from "./functions";

export const Trigger = (props: PropTypes) => {
  const { context, entity, records } = props;

  const triggerRecord = (apiCall: ApiCall) =>
    recordsApi(apiCall).trigger(entity.name, records[0].properties.id);

  const triggerRecordList = (apiCall: ApiCall) =>
    recordsApi(apiCall).bulkTrigger(entity.name, mapPayload(records));

  const onTrigger = (apiCall: ApiCall, dismiss: () => void) => {
    const triggerRequest =
      records.length > 1 ? triggerRecordList(apiCall) : triggerRecord(apiCall);

    return triggerRequest.then(dismiss);
  };

  const woEntity = context.entities[entity.arguments.workOrderEntity];
  const woEntityName = getLocalizedName(woEntity);
  const swoEntityName = getLocalizedName(entity);
  const requiresAuthentication =
    hasProtectedColumns(entity) || hasProtectedColumns(woEntity);

  return (
    <Action
      {...props}
      requiresAuthentication={requiresAuthentication}
      title={_("Trigger")}
      btnLabel={_("Trigger")}
      size="large"
      onOk={onTrigger}
    >
      <AlertInfo
        message={_(
          "This action will open a {WORK_ORDER_ENTITY} record for each asset of the triggered {SWO_ENTITY} record as an example. It won't affect the schedule or the last performed date.",
        )
          .replace("{WORK_ORDER_ENTITY}", woEntityName)
          .replace("{SWO_ENTITY}", swoEntityName)}
      />
    </Action>
  );
};
